@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
/* theme.css */
/* :root {
  --background-color: #ffffff;
  --text-color: #000000;
  --primary-color: #FF7A50;
  --secondary-color: #464B4B;
  --hover-color: #orange-700;
}

[data-theme="dark"] {
  --background-color: #1A1C1C;
  --text-color: #ffffff;
  --primary-color: #FF7A50;
  --secondary-color: #464B4B;
  --hover-color: #FF4500;
} */
:root {
  --background-color: #222626;
  --achbackground-color: #222626;
  --plancard-color: #222626;
  --pinkbackground-color: #2c3131;
  --achcard-color: #2c3131;
  --featurebackground-color: #2c3131;
  --planbackground-color: #2c3131;
  --inputcolor-color: #464b4b;
  --lightpink-color: #222626;
  --imagebgcolor-color: #464b4b;
  --subsbgcolor-color: #1a1c1c;
  --faqmainbg-color: #222626;
  --substextcolor: #ff7a50;
  --secondsubsbgcolor-color: #464b4b;
  --secondsubstextcolor-color: #5a5c5e;
  --text-color: #ffffff;
  --primary-color: #ff7a50;
  --faqbg-color: #2c3131;
  --secondary-color: #464b4b;
  --hover-color: #ff4500;
  /* Add other variables as needed */
}
.menu-link.active {
  color: var(--text-color);
}

.menu-link.active .menu-icon i {
  color: var(--text-color);
}

[data-theme="dark"] {
  --background-color: #ffffff;
  --pinkbackground-color: #fdfcf9;
  --achbackground-color: #fdfcf9;
  --achcard-color: #ffffff;
  --plancard-color: #fdfcf9;
  --featurebackground-color: #ffffff;
  --planbackground-color: #ffffff;
  --inputcolor-color: #eaeaea;
  --lightpink-color: #fff9f8;
  --imagebgcolor-color: #f8f5f4;
  --text-color: #000000;
  --primary-color: #ff7a50;
  --faqbg-color: #fff9f8;
  --secondary-color: #464b4b;
  --hover-color: #orange-700;
  --subsbgcolor-color: #ff8b42;
  --substextcolor: #fff8f6;
  --secondsubsbgcolor-color: #eaeaea;
  --secondsubstextcolor-color: #5a5c5e;
  /* Add other variables as needed */
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: var(--background-color);
  color: var(--text-color);
}
 .custom-group:hover {
  background-color: #c4c5c73f; /* Tailwind's bg-gray-500 */
}


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
* {
  font-family: "Montserrat", sans-serif;
  /* transition: 0.1s; */
}
.cardshadow{
  -webkit-box-shadow: 3px 3px 10px 3px rgba(222, 222, 222, 0.62);
-moz-box-shadow: 3px 3px 10px 3px rgba(222, 222, 222, 0.62);
box-shadow: 3px 3px 10px 3px rgba(222, 222, 222, 0.62);
}
.backimage {
  background: url("/public/assets/org.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg-orangelinewithicons {
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 40% top;
}
.carousel-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 16px;
  padding: 20px;
}

.carousel-card {
  flex: 0 0 auto;
  width: 300px;
  scroll-snap-align: start;
  background: #2c3131;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.controls button {
  padding: 10px 20px;
  margin: 10px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
}

.controls button:disabled {
  background-color: grey;
}

.dot-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.dot-controls span {
  display: inline-block;
}
/* Additional CSS for positioning and styling dots */

/* General styles for pagination bullets */
.swiper-pagination-bullet {
  background: gray !important; /* Default bullet color */
  opacity: 1 !important; /* Make sure dots are always opaque */
  width: 8px !important; /* Specify size to ensure visibility */
  height: 8px !important; /* Specify size to ensure visibility */
  margin: 0 4px !important; /* Add some horizontal spacing */
}

/* Styles for the active pagination bullet */
.swiper-pagination-bullet-active {
  background: #ff7a50 !important;
  width: 8px !important;
  height: 8px !important;
}

/* Adjusting the position of the pagination itself */
.swiper-pagination {
  position: relative !important;
  margin-top: 30px !important;
}

@media (max-width: 800px) {
  .mycard {
    margin: 5%;
  }
  .mycarousal {
    margin: 5%;
  }
  .bgimage {
    background: none;
  }
}

/* CSS Loader */

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55px;
  aspect-ratio: 1;
  --g1: conic-gradient(from 90deg at 3px 3px, #0000 90deg, #ff7a50 0);
  --g2: conic-gradient(from -90deg at 22px 22px, #0000 90deg, #ff7a50 0);
  background: var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2);
  background-size: 25px 25px;
  background-repeat: no-repeat;
  /* background: black; */
  animation: l7 1.5s infinite;
}
@keyframes l7 {
  0% {
    background-position: 0 0, 0 100%, 100% 100%;
  }
  25% {
    background-position: 100% 0, 0 100%, 100% 100%;
  }
  50% {
    background-position: 100% 0, 0 0, 100% 100%;
  }
  75% {
    background-position: 100% 0, 0 0, 0 100%;
  }
  100% {
    background-position: 100% 100%, 0 0, 0 100%;
  }
}
.myslide {
  display: flex !important;
  height: auto !important;
}

.myslide1 {
  display: flex !important;
  height: auto !important;
  margin-right: 16px !important;
}

/* .swiper-slide {
  display: flex !important;
} */
@media (max-width: 500px) {
  .myslide {
    display: block !important ;
  }

  /* .myslide1 {
  display: block !important;
} */
}

.ckeditor-output h1 {
  font-size: 40px;
}

.ckeditor-output h2 {
  font-size: 35px;
  font-weight: bolder;
}
.ckeditor-output h3 {
  font-size: 32px;
  padding: 1rem 0;
  font-weight: bolder;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; 
}
@media (max-width: 768px) {
  .row {
    display: block;
  }
  
  .column {
    display: block;
  }
  
  .content {
    flex-grow: 1;
    display: block;
  }
  
}
/* textarea {
  resize: none; 
  overflow-y: hidden; 
  overflow: auto; 
  min-height: 50px; 
  max-height: 100px; 
} */
.notification-bell {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 0.8rem;
}

.notification-dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item button {
  background: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}
